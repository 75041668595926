import React from 'react'

class GameRedirect extends React.Component {

  componentDidMount() {
    window.location = "/media"
  }

  render() {
    return (
      <div>Whoops! This page has been taken offline!</div>
    );
  }
}

export default GameRedirect;